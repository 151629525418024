//事例スライダー
.c-workSlider {
  max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
  &__list {}
  &__item {
    width: 100%;
    padding-top: (130 / 554 * 100%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  &__label {
    background-color: rgba($keyColor, .8);
    margin: 0;
    width: 100%;
    color: $white;
    padding: 7px 5px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  //パーツ調整
  .c-box {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    li {
      max-width: 18px;
      width: 100%;
      height: 13px;
      button {
        width: 100%;
        height: 100%;
        padding: 0;
        &:before {
          content: '';
          width: 100%;
          width: 10px;
          height: 100%;
          height: 10px;
          border-radius: 10px;
          opacity: 1;
          background-color: #C6C6C6;
        }
      }

      &.slick-active {
        button {
          &:before {
            opacity: 1;
            background-color: $keyColor;
          }
        }
      }
    }
  }
  .p-slider-box {
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
  }
  .c-workSlider__item {
    width: 100% !important;
    // margin: 0 9.5px;
    @media screen and (max-width: 600px) {
      // width: 27% !important;
      // margin: 0 9.5px;
    }
  }
  .slick-slider {
    text-align: center;
  }
  // .slick-slide {
  //   @media screen and (max-width: 768px) {
  //    width: 50% !important;
  //   }
  // }
//   .c-workSlider__list {
//     width: 94%;
//     margin: 0 auto;
//   }
//   .slick-slide {
//     margin:0 10px;
// }
}