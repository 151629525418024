//全体共通で使うCSS定義
html {
  &.open {
    overflow: hidden;

    body {
      overflow: hidden;
    }
  }
}

body {
  color: $textColor;
  font-family: $sanserif;
  line-height: $lineHeight;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pwid";

  @if $is-fixed==true {
    margin-top: $headerHeight;

    @include mq-max(md) {
      margin-top: $headerHeight-sp;
    }
  }
}


//  見出し
///////////////////////////////////////////////////////////////////////////////////////////////////////
h2,
h3,
h4,
h5 {
  line-height: 1.5;
}

h1 {
  font-size: 30px;

  @include mq-max(xs) {
    font-size: 24px;
  }
}

h2 {
  font-size: 28px;
  margin-bottom: 30px;

  @include mq-max(xs) {
    font-size: 22px;
    margin-bottom: 24px;
  }
}

h3 {
  font-size: 22px;
  margin-bottom: 15px;

  @include mq-max(xs) {
    font-size: 18px;
    margin-bottom: 12px;
  }
}

h4 {
  font-size: 21px;
  margin-bottom: 10px;

  @include mq-max(xs) {
    font-size: 18px;
  }
}

h5 {
  font-size: 18px;
  margin-bottom: 10px;

  @include mq-max(xs) {
    font-size: 16px;
  }
}

//  文章
///////////////////////////////////////////////////////////////////////////////////////////////////////
a {
  text-decoration: none;
  color: $keyColor;

  &[href^="javascript:void(0)"] {
    pointer-events: none;
  }

  &[target="_blank"] {
    &:after {
      @include fa('f35d');
      margin-left: 10px;
      -webkit-font-smoothing: antialiased;
    }
  }

  &[href$=".pdf"] {
    &:after {
      @include fa('f1c1');
      font-weight: 400;
      color: $red;
      margin-left: 10px;
      -webkit-font-smoothing: antialiased;
    }
  }

  &:not(.c-btn) {
    color: $keyColor;
    transition: color $transition;

    &:hover {
      color: darken($keyColor, 10%);
    }
  }

}

p {
  font-size: 16px;
  margin-bottom: 30px;
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq-max(xs) {
    font-size: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-large {
    font-size: 18px;
  }
}

blockquote {}


//  強調
strong {
  font-weight: 600;
}

//  画像
figure {
  img {
    width: 100%;
  }
}

// Lists
ul,
ol {
  list-style-type: none;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
//  電話番号CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////
[data-js="tel"] {
  &>a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

//見出しなどの文字間調整
.let-space {
  letter-spacing: 0.08em;
}

//お問い合わせ
//inquiry

.inquiry {
  // background: url('/inc/image/top/bg_inquiry.jpg')no-repeat center / cover;
  background-color: $keyColor;
  padding-top: 65px;
  padding-bottom: 65px;
  padding-right: 30px;
  padding-left: 30px;

  .inquiry_box {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .tac {
    text-align: center;
    margin-bottom: 25px;
    color: #000;

    @media screen and (max-width: 568px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  .flex {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
    }

    .left-box {
      background-color: #fff;
    }

    .left-box {
      @media screen and (max-width: 900px) {
        text-align: center;
      }

      @media screen and (max-width: 568px) {
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }

      @media screen and (max-width: 390px) {
        margin-left: 10px;
        margin-right: 10px;
      }

      h3 {
        // padding: 10px 60px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 45px;
        padding-right: 60px;
        margin-bottom: 0;
        line-height: 75px;

        @media screen and (max-width: 568px) {
          font-size: 14px;
          padding: 0px 25px;
          line-height: 60px;
          text-align: center;
        }

        @media screen and (max-width: 350px) {
          font-size: 14px;
        }
      }
    }

    .right-box {
      padding: 10px 70px;
      padding-left: 40px;

      @media screen and (max-width: 900px) {
        text-align: center;
        max-width: 400px;
        margin: 0 auto;
      }

      @media screen and (max-width: 568px) {
        padding: 0px 25px;
      }

      h3 {
        margin-bottom: 0;
        font-size: 26px;
        color: #fff;
        letter-spacing: 0.12em;
        line-height: 0.6em;
        text-align: right;

        // @media screen and (max-width: 768px){
        //   text-align: center;
        //  }
        @media screen and (max-width: 568px) {
          font-size: 23px;
          text-align: right;
        }

        @media screen and (max-width: 350px) {
          font-size: 19px;
          text-align: right;
        }
      }
    }
  }

  span {
    // font-size: 16px;
    // letter-spacing: 0em;
    color: #000;

    @media screen and (max-width: 568px) {
      font-size: 14px;
    }

    @media screen and (max-width: 350px) {
      font-size: 12px;
    }
  }

  .c-btn {
    padding: 0px !important;
    box-shadow: none;
  }

  .p-icon-rela {
    position: relative;

    &:before {
      content: '';
      display: block;
      background: url('/inc/image/common/icon_tel.png')no-repeat center / contain;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 74%;
      left: 10px;
      transform: translateY(-50%);
      z-index: 100;

      @media screen and (max-width: 568px) {
        left: 6px;
      }

      @media screen and (max-width: 368px) {
        left: -7px;
        width: 25px;
        height: 25px;
      }

      // @include mq-max(md) {
      //   left: 50px;
      // }

      // @include mq-max(sm) {
      //   left: 80px;
      //   width: 940px * 0.7;
      //   height: 511px * 0.7;
      // }

      // @include mq-max(xs) {
      //   left: 80px;
      //   width: 940px * 0.5;
      //   height: 511px * 0.5;
      // }
    }
  }

  .span_none {
    @media screen and (max-width: 568px) {
      font-size: 22px;
    }
  }
}

//2カラムのCSS
.flex-2-columm {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
}

.flex-main-content {
  width: 70%;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-right: 30px !important;

  @media screen and (max-width: 768px) {
    margin-right: 0px !important;
  }
}

.flex-side-bar {
  max-width: 330px;
  // width: 30%;
  padding-top: 90px;
  padding-left: 15px;
  margin: 0 auto;
  padding-right: 15px;

  @media screen and (max-width: 968px) {
    max-width: 240px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 568px) {
    display: none;
  }
}

.side-img-box {
  img {
    width: 100%;
    margin-bottom: 15px;
  }
}

//コンテンツ間の余白調整
.p-pb120 {
  padding-bottom: 120px !important;
}

//titleの頭のカラー
.p-title-headC {
  color: $accentColor !important;
  font-size: 40px !important;
}

//下層ページのサイドバー　スマホ版
.p-slider-sp-only {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
}

//よくあるご質問へ移動
.p-anchor-l-section {
  padding: 0 0 30px !important;

  @media screen and (max-width: 1168px) {
    padding: 0 0 30px !important;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 30px !important;
  }
}


//サイドボタン(共通)
.c-sideBtn {
  position: fixed;
  top: 55%;
  right: 0;
  transform: translateY(-50%);
  width: 120px;
  height: 120px;
  border-radius: 15px 0px 0px 15px;
  z-index: 5;
  background-color: $accentColor;
  transition: all 0.2s ease;

  &:hover {
    // right: 0;
    transition: all 0.2s ease;
    opacity: 0.7;
    // background-color: $white;
    color: darken($keyColor, 10%);
  }

  @include mq-max(sm) {
    height: 60px;
    border-radius: 0px;
    top: auto;
    bottom: 0;
    right: 0;
    transform: none;
    width: 100%;
  }

  &__list {
    @include mq-max(sm) {
      position: relative;
    }
  }

  &__item {
    position: relative;
    right: 0px;
    bottom: -35px;
    transition: all 0.2s ease;
    border-bottom: 1px solid $white;

    &:last-child {
      border-bottom: none;
    }

    @include mq-max(sm) {
      border-bottom: none;
      border-right: 1px solid $white;
      bottom: 0px;

      &:last-child {
        border-right: none;
      }
    }

    // &:hover {
    //   right: 0;
    //   transition: all 0.2s ease;
    //   opacity: 0.6;
    // }

    @include mq-max(sm) {
      position: relative;
      right: 0;
      // width: 50%;
    }



    //ネット注文
    &--net {
      // @include mq-max(sm) {
      //   width: calc(110 / 375 * 100%);
      // }

      .c-sideBtn__link {
        border-radius: 6px 0 0 0;

        @include mq-max(sm) {
          border-radius: 0;
        }

        br {
          @include mq-max(sm) {
            display: none;
          }
        }

        &>span {
          // @include mq-max(sm) {
          //   left: 12px;
          // }

          &:before {
            content: '';
            background: url(/inc/image/common/ico_sidebtn.png) no-repeat center/contain;
            width: 47px;
            height: 47px;
            display: block;
            position: absolute;
            top: -58px;
            left: 4px;

            @include mq-max(sm) {
              top: -8px;
              left: -59px;
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }



  }

  &__link {
    border-radius: 6px 0 0 6px;
    // color: $white !important;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.08em;
    display: block;
    padding: 34px 14.4px 10px 3.4px;
    text-align: center;
    position: relative;

    @include mq-max(sm) {
      border-radius: 0;
      font-size: 12px;
      padding: 18px 10px;
      text-align: center;
      padding-left: 30px;
    }

    &:after {
      content: none !important;
    }

    span {
      position: relative;

      @include mq-max(sm) {
        display: inline-block;
      }
    }

    &:hover {
      border-radius: 6px 0 0 6px !important;

      @include mq-max(sm) {
        border-radius: 0 !important;
      }
    }
  }




}

.p-ico-hover {
  color: #fff;
}

.p-ico-hover:after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  top: 52%;
  right: -20px;
  transform: translateY(-50%);
  transition: right 0.2s ease;
}


// * {
//   @include mq-max(sm) {
//     min-height: 0;
//     min-width: 0;
//   }
// }