/*
モジュール系おまとめファイル
*/

// ヘッダー
@import "header";

// フッター
@import "footer";

// グローバルナビゲーション
@import "navigation";

 // ページトップに戻る
@import "pageTop";

 // ボックス
@import "box";

 // ボタン
@import "button";

 // ページネーション
@import "pagination";

//テーブル
@import "table";

//リスト
@import "list";

//見出し
@import "heading";

//アコーディオン
@import "accordion";

//タブ
@import "tab";

//テキストの装飾
@import "txt";

//沿革
@import "timeline";

// ラベル
@import "label";

//引用
@import "blockquote";

//リンク
@import "link";

//お知らせ
@import "newslist";

// クラス付与型ホバーアクション
@import "hover";

//アニメーション
@import "animation";

//グループ会社
@import "groupCompany";

//リクルートCVエリア
@import "recruitCV";

//カード
@import "card";

//ポイントボックス
@import "pointBox";

//事例スライダー
@import "workSlider";

//イメージリスト
@import "imgList";

//アイコンカード
@import "icoCard";

//ページ内アンカーリンク
@import "innerAnchor";

//フロー
@import "flow";

//企業理念
@import "philosophy";

//代表挨拶
@import "greet";

//行動指針
@import "guide";

//会社概要
@import "overview";

//沿革
@import "history";

//背景
@import "bg";