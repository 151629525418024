.pageTop{
  position: fixed;
  bottom: 55px;
  right: 15px;
  z-index: 1000;
  @include mq-max(sm) {
    bottom: 75px;
  }
  &:after {
    @include fa('f077');
    display: inline-block;
    color: #fff;
    padding: 20px;
    background-color: $keyColor;
    border-radius: 50%;
    line-height: 1;
  }
}
